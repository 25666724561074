/* critters:include start */

@use '@angular/material' as mat;

@include mat.core();

$app-typography: mat.define-typography-config();

$app-admin-primary: mat.define-palette(mat.$indigo-palette);
$app-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-admin-warn: mat.define-palette(mat.$red-palette);
$app-admin-theme: mat.define-light-theme((
	color: (
		primary: $app-admin-primary,
		accent: $app-admin-accent,
		warn: $app-admin-warn,
	),
	density: 0
));

$app-admin-primary-dark: mat.define-palette(mat.$deep-purple-palette);
$app-admin-accent-dark: mat.define-palette(mat.$green-palette, A200, A100, A400);
$app-admin-warn-dark: mat.define-palette(mat.$red-palette);
$app-admin-theme-dark: mat.define-dark-theme((
	color: (
		primary: $app-admin-primary-dark,
		accent: $app-admin-accent-dark,
		warn: $app-admin-warn-dark,
	),
	density: 0
));
@include mat.all-component-typographies($app-typography);

@include mat.all-component-themes($app-admin-theme);

.app-dark-theme{
	@include mat.all-component-colors($app-admin-theme-dark);
}

$app-font-size: 24px;

html, body{
	margin: 0;
	height: 100%;
	font-family: Roboto, sans-serif;
}

body.app-dark-theme{
	background-color: var(--mat-sidenav-content-background-color);
}

a{
	white-space: nowrap;
	cursor: pointer;
	font-weight: bold;
	color: unset;
}

a.wrapped{white-space: normal;}

a.no-decoration{text-decoration: none;}

a.white-space-normal{white-space: normal;}

.mat-icon{font-size: $app-font-size;}

.mat-icon.app-12{font-size: 12px;height: 12px;width: 12px;}

.mat-icon.app-14{font-size: 14px;height: 14px;width: 14px;}

.mat-icon.app-16{font-size: 16px;height: 16px;width: 16px;}

.mat-icon.app-18{font-size: 18px;height: 18px;width: 18px;}

.mat-icon.app-20{font-size: 20px;height: 20px;width: 20px;}

.mat-icon.app-24{font-size: 24px;height: 24px;width: 24px;}

.mat-icon.app-30{font-size: 30px;height: 30px;width: 30px;}

.mat-icon.app-36{font-size: 36px;height: 36px;width: 36px;}

.mat-icon.app-48{font-size: 48px;height: 48px;width: 48px;}

.mat-icon.app-dark{color: rgba(0, 0, 0, 0.54) !important;}

.mat-icon.app-pointer{cursor: pointer;}

.mat-sidenav-container{height: 100%;}

.mat-mdc-card-header-text{
	display: flex;
	flex-direction: column;
	align-self: center;
}

.app-primary-color{color: mat.get-color-from-palette($app-admin-primary, 900) !important;}

.app-dark-theme .app-primary-color{color: mat.get-color-from-palette($app-admin-primary-dark, 900) !important;}

.app-accent-color{color: mat.get-color-from-palette($app-admin-accent, 900) !important;}

.app-dark-theme .app-accent-color{color: mat.get-color-from-palette($app-admin-accent-dark, 900) !important;}

.app-warn-color{color: mat.get-color-from-palette($app-admin-warn, 900) !important;}

.app-dark-theme .app-warn-color{color: mat.get-color-from-palette($app-admin-warn-dark, 900) !important;}

.app-primary{
	color: mat.get-contrast-color-from-palette($app-admin-primary, 900) !important;
	background: mat.get-color-from-palette($app-admin-primary, 900) !important;
}

.app-dark-theme .app-primary{
	color: mat.get-contrast-color-from-palette($app-admin-primary-dark, 900) !important;
	background: mat.get-color-from-palette($app-admin-primary-dark, 900) !important;
}

.app-accent{
	color: mat.get-contrast-color-from-palette($app-admin-accent, 900) !important;
	background: mat.get-color-from-palette($app-admin-accent, 900) !important;
}

.app-dark-theme .app-accent{
	color: mat.get-contrast-color-from-palette($app-admin-accent-dark, 900) !important;
	background: mat.get-color-from-palette($app-admin-accent-dark, 900) !important;
}

.app-warn{
	color: mat.get-contrast-color-from-palette($app-admin-warn, 900) !important;
	background: mat.get-color-from-palette($app-admin-warn, 900) !important;
}

.app-dark-theme .app-warn{
	color: mat.get-contrast-color-from-palette($app-admin-warn-dark, 900) !important;
	background: mat.get-color-from-palette($app-admin-warn-dark, 900) !important;
}

.app-12{font-size: 12px !important;}

.app-14{font-size: 14px !important;}

.app-16{font-size: 16px !important;}

.app-18{font-size: 18px !important;}

.app-20{font-size: 20px !important;}

.app-24{font-size: 24px !important;}

.app-30{font-size: 30px !important;}

.app-36{font-size: 36px !important;}

.app-48{font-size: 48px !important;}

.app-pointer{cursor: pointer !important;}


.dialog-responsive{width: 60%;}

.tab-active, .tab-active span{
	background-color: mat.get-color-from-palette($app-admin-primary, 900) !important;
	color: mat.get-contrast-color-from-palette($app-admin-primary, 900) !important;
	border-radius: 50px;
	overflow: hidden;
}

.app-dark-theme .tab-active, .app-dark-theme .tab-active span{
	background-color: mat.get-color-from-palette($app-admin-primary-dark, 900) !important;
	color: mat.get-contrast-color-from-palette($app-admin-primary-dark, 900) !important;
}

.app-section-header-center{
	margin-bottom: 16px !important;
}

.file-upload-drop-zone{}
.file-upload-drop-zone-content{position: relative;}
.file-upload-drop-zone .panel-glass {
	width: 100%;
	height: 100%;
	backdrop-filter: blur(2px) opacity(1) sepia(100%);
	position: absolute;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration-style: wavy;
	text-shadow: 0 0 10px black;
	color:white;
}
.ngx-file-drop__drop-zone--over .panel-glass {
	z-index: 11 !important;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////


@media only screen and (max-width: 599.98px){
	.dialog-responsive{width: 90%;}
}

@media only screen and (min-width: 600px) and (max-width: 959.98px){
	.dialog-responsive{width: 60%;}
}

@media only screen and (min-width: 960px) and (max-width: 1279.98px){
	.dialog-responsive{width: 40%;}
}

@media only screen and (min-width: 1280px) and (max-width: 1919.98px){
	.dialog-responsive{width: 40%;}
}

@media only screen and (min-width: 1920px){
	.dialog-responsive{width: 20%;}
}


/* critters:include end */




